<template>
  <div>404 / Страница не найдена</div>
</template>

<script>
export default {
  async asyncData({ res }) {
    res.status(404);
  },
};
</script>
